$(function() {
    /*$('a[href^="#"]').on('click', function(event) {
        var offset = 0;
        var target = $( $(this).attr('href') );
        var header = $('#fixed-header');

        if(header.is(":visible")){
            offset = header.height();
        }

        if(offset == 112){
            offset = 0;
        }

        if( target.length ) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top - offset
            }, 1000);
        }
    });*/
});